import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const ImprintPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Impressum — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Impressum " + site.siteMetadata.description}
        />
      </Helmet>
      <div className="contact-container">
        <h1>Impressum</h1>
        <p>Verantwortlich für den Inhalt:</p>
        <p>
          Tischlermeister Manuel Herzog
          <br />
          Handwerkskammer Düsseldorf, 1202818
          <br />
          Steuernummer: 129/5047/1874
          <br />
          Beethovenstr. 26
          <br />
          42655 Solingen
          <br />
          <br />
          Tel.:0212/2239800
          <br />
          Fax: 0212/2239801
          <br />
          <br />
          E-Mail: info@woodlooksgood.de
        </p>
        <p>
          Haftungshinweis: Trotz sorgfältiger inhaltlicher Kontrolle übernehmen
          wir keine Haftung für die Inhalte externer Links. Für den Inhalt der
          verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
        </p>
      </div>
    </Layout>
  );
};
export default ImprintPage;
export const pageQuery = graphql`
  query ImprintPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
